import React, { useState } from "react";

export default function Name({ onSetName }) {
  const [inputValue, setInputValue] = useState("");
  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleSubmit = (event) => {
    if (inputValue.trim() === "") {
      alert("Mets ton nom connard");
    } else if (inputValue.trim().toLocaleLowerCase() === "margot") {
      const password = prompt("Mot de passe ?");
      if (password === "123soleil") {
        onSetName(inputValue.trim());
      } else {
        alert("Mot de passe incorrect !");
      }
    } else {
      onSetName(inputValue.trim());
    }
    event.preventDefault();
  };

  return (
    <div className="flex justify-center items-center bg-shapes w-full h-screen px-5">
      <div>
        <h1 className="text-white font-black text-4xl text-center">
          Quel est ton nom ?
        </h1>
        <form onSubmit={handleSubmit} className="flex flex-col my-5">
          <input
            type="text"
            name="name"
            className="h-10 rounded-md focus:outline-none px-2 text-lg"
            value={inputValue}
            onChange={handleChange}
          />
          <input
            type="submit"
            className="py-2 rounded-full mt-20 bg-green-400 text-xl font-bold text-gray-900 hover:bg-green-600 cursor-pointer"
            value="Commencer"
          />
        </form>
      </div>
    </div>
  );
}
