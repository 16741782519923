export const data = [
  {
    id: 1,
    question: "Tu préfères faire...",
    answers: [
      {
        text: "Des yaourts",
        image: require("./assets/images/yaourt.jpg"),
      },
      {
        text: "Des crèmes dessert",
        image: require("./assets/images/cremedessert.jpg"),
      },
    ],
  },
  {
    id: 2,
    question: "Ou",
    answers: [
      {
        text: "Eclater des gens à Mario Kart",
        image: require("./assets/images/mariokart.jpg"),
      },
      {
        text: "Eclater des gens à Smash Bros",
        image: require("./assets/images/smash.jpg"),
      },
    ],
  },
  {
    id: 3,
    question: "Ou",
    answers: [
      {
        text: "1",
        image: require("./assets/images/cuite1.jpg"),
      },
      {
        text: "2",
        image: require("./assets/images/cuite2.jpg"),
      },
    ],
  },
  {
    id: 4,
    question: "Tout le monde s'en blc de bulbizarre",
    answers: [
      {
        text: "Salamèche",
        image: require("./assets/images/salameche.jpg"),
      },
      {
        text: "Carapuce",
        image: require("./assets/images/carapuce.jpg"),
      },
    ],
  },
  {
    id: 5,
    question: "Ou",
    answers: [
      {
        text: "Voile",
        image: require("./assets/images/voile.jpg"),
      },
      {
        text: "Surf",
        image: require("./assets/images/surf.jpg"),
      },
    ],
  },
  {
    id: 6,
    question: "On appelle ça Hydromel dans le reste du monde",
    answers: [
      {
        text: "Cidre",
        image: require("./assets/images/cidre.jpg"),
      },
      {
        text: "Chouchen",
        image: require("./assets/images/chouchen.jpg"),
      },
    ],
  },
  {
    id: 7,
    question: "Ou",
    answers: [
      {
        text: "Paysanne",
        image: require("./assets/images/paysanne.jpg"),
      },
      {
        text: "Noble",
        image: require("./assets/images/noble.jpg"),
      },
    ],
  },
  {
    id: 8,
    question: "Faire soirée avec des...",
    answers: [
      {
        text: "Vétos",
        image: require("./assets/images/vetos.jpg"),
      },
      {
        text: "Médecines",
        image: require("./assets/images/medecines.jpg"),
      },
    ],
  },
  {
    id: 9,
    question: "Super photogénique la meuf jsuis jaloux",
    answers: [
      {
        text: "1",
        image: require("./assets/images/photo1.jpg"),
      },
      {
        text: "2",
        image: require("./assets/images/photo2.jpg"),
      },
    ],
  },
  {
    id: 10,
    question: "Ou",
    answers: [
      {
        text: "Khuong est le meilleur",
        image: null,
      },
      {
        text: "Khuong est le meilleur",
        image: null,
      },
    ],
  },
  {
    id: 11,
    question: "Ou",
    answers: [
      {
        text: "Disney",
        image: require("./assets/images/disney.jpg"),
      },
      {
        text: "Studio Ghibli",
        image: require("./assets/images/ghibli.jpg"),
      },
    ],
  },
  {
    id: 12,
    question: "Beaucoup trop mims 🥰",
    answers: [
      {
        text: "1",
        image: require("./assets/images/fb1.jpg"),
      },
      {
        text: "2",
        image: require("./assets/images/fb2.jpg"),
      },
    ],
  },
  {
    id: 13,
    question: "Je suis amoureux de toi",
    answers: [
      {
        text: "Simp",
        image: null,
      },
      {
        text: "Simp",
        image: null,
      },
    ],
  },
];

export const firebaseConfig = {
  apiKey: "AIzaSyCle5ecPbkwpyAnJhukfOHHx1eaDlCOgmg",
  authDomain: "margotfanaccount.firebaseapp.com",
  projectId: "margotfanaccount",
  storageBucket: "margotfanaccount.appspot.com",
  messagingSenderId: "359608120243",
  appId: "1:359608120243:web:6a93682282d303f4ed51c6",
};
