import React, { useEffect, useState } from "react";
import { initializeApp } from "firebase/app";
import { getFirestore, updateDoc, doc, getDoc } from "firebase/firestore/lite";

import { firebaseConfig } from "../data";

export default function Score({ answers, name }) {
  console.log(answers);
  const [display, setDisplay] = useState("...");

  useEffect(async () => {
    const firebase = initializeApp(firebaseConfig);
    const firestore = getFirestore(firebase);
    const docRef = doc(firestore, "/answers/margot");
    if (name.toLocaleLowerCase() === "margot") {
      await updateDoc(docRef, "answers", answers);
      setDisplay("✌️");
    } else {
      const doc = await getDoc(docRef);
      const correctAnswers = doc.data().answers;
      const result =
        (Object.keys(answers).reduce((acc, key) => {
          return correctAnswers[key] === answers[key] ? acc + 1 : acc;
        }, 0) /
          Object.keys(answers).length) *
        100;
      setDisplay(`${Math.round(result)}%`);
    }
  }, []);
  return (
    <div className="flex justify-center items-center bg-shapes w-full h-screen px-5">
      <div className="flex flex-col">
        <h1 className="text-white font-black text-4xl text-center mb-16">
          {name.toLocaleLowerCase() === "margot"
            ? "J'espère que c'était fun !"
            : "Tu as eu un score de..."}
        </h1>
        <strong className="text-white font-black text-9xl text-center mb-5">
          {display}
        </strong>
        <p className="text-white font-bold text-xl text-center">
          {name.toLocaleLowerCase() === "margot"
            ? "Tu peux fermer dès que l'emoji apparaît"
            : "Si t'as au-dessus de 80%, pense à consulter"}
        </p>
      </div>
    </div>
  );
}
