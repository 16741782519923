import React, { useState } from "react";
import Name from "./pages/Name";
import Game from "./pages/Game";
import Score from "./pages/Score";

export function App() {
  const [name, setName] = useState("");
  const [answers, setAnswers] = useState(null);
  if (name === "") {
    return <Name onSetName={setName} />;
  } else if (answers === null) {
    return <Game onSetAnswers={setAnswers} name={name} />;
  } else {
    return <Score answers={answers} name={name} />;
  }
}
