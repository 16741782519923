import React, { useState, useEffect } from "react";
import { data } from "../data";

export default function Game({ onSetAnswers, name }) {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState({});

  const isDone =
    name.toLocaleLowerCase() === "margot"
      ? currentQuestion === data.length - 1
      : currentQuestion === data.length - 2;
  const handleClick = (answer) => {
    setAnswers({ ...answers, [data[currentQuestion].id]: answer });
    if (!isDone) {
      setCurrentQuestion(currentQuestion + 1);
    }
  };

  useEffect(() => {
    if (
      name.toLocaleLowerCase() === "margot"
        ? Object.keys(answers).length === data.length
        : Object.keys(answers).length === data.length - 1
    ) {
      onSetAnswers(answers);
    }
  }, [answers]);

  return (
    <div className="flex h-screen flex-col md:flex-row relative">
      <strong className="bg-white text-3xl font-bold text-center text-gray-900 p-2 rounded absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50">
        {data[currentQuestion].question}
      </strong>
      <div
        className="bg-blue-500 hover:bg-blue-600 border-b-4 md:border-b-0 md:border-r-4 md:w-1/2 md:h-full w-full h-1/2 border-white cursor-pointer relative brightness-90 hover:brightness-110 duration-100"
        style={{
          backgroundImage: `url(${data[currentQuestion].answers[0].image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
        onClick={() => handleClick(data[currentQuestion].answers[0].text)}
      >
        <strong className="bg-white text-xl text-center font-bold text-gray-900 p-2 rounded absolute bottom-1/4 left-1/2 transform -translate-x-1/2">
          {data[currentQuestion].answers[0].text}
        </strong>
      </div>
      <div
        className="bg-red-500 hover:bg-red-600 border-t-4 md:border-t-0 md:border-l-4 md:w-1/2 md:h-full w-full h-1/2 border-white cursor-pointer relative brightness-90 hover:brightness-110 duration-100"
        style={{
          backgroundImage: `url(${data[currentQuestion].answers[1].image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
        onClick={() => handleClick(data[currentQuestion].answers[1].text)}
      >
        <strong className="bg-white text-xl text-center font-bold text-gray-900 p-2 rounded absolute bottom-1/4 left-1/2 transform -translate-x-1/2">
          {data[currentQuestion].answers[1].text}
        </strong>
      </div>
    </div>
  );
}
